import React, { useContext, useRef, useState } from "react";
import { FacebookProvider, Login } from "react-facebook";
import ReactGA from "react-ga";
import { GoogleLogin } from "react-google-login";
import { Link, useNavigate } from "react-router-dom";
import CheckButton from "react-validation/build/button";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { isEmail } from "validator";
import facebookIcon from "../../../assets/icons/fbIcon.png";
import googleIcon from "../../../assets/icons/googleIcon.png";
import AuthService from "../../../services/auth.service";
import { ACTIONS } from "../../../store/reducer";
import { AppContext } from "../../../store/store";
import styles from "./Signup.module.scss";
ReactGA.initialize("UA-219193792-1");

function Signup(props) {
  const { setLoginMode, closeModal } = props;

  const history = useNavigate();
  const { dispatch } = useContext(AppContext);

  const form = useRef();
  const checkBtn = useRef();

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirm] = useState("");
  const [referral_code, set_referral_code] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const required = (value) => {
    if (!value) {
      return (
        <div className={styles.errorMsg}>
          <p>This field is required!</p>
        </div>
      );
    }
  };

  const validEmail = (value) => {
    if (!isEmail(value)) {
      return (
        <div className={styles.errorMsg}>
          <p>This is not a valid email.</p>
        </div>
      );
    }
  };

  const verifyUsername = (value) => {
    if (value.length < 3 || value.length > 20) {
      return (
        <div className={styles.errorMsg}>
          <p>Your username must be between 3 and 16 characters.</p>
        </div>
      );
    }
  };

  const verifyPassword = (value) => {
    if (value.length < 6 || value.length > 40) {
      return (
        <div className={styles.errorMsg}>
          <p>The password must be between 6 and 40 characters.</p>
        </div>
      );
    }
  };

  const equalPassword = (value, props, components) => {
    const password = components["password"][0]["value"];
    if (value !== password) {
      return (
        <div className={styles.errorMsg}>
          <p>Password is not the same.</p>
        </div>
      );
    }
  };

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const onChangeConfirmPassword = (e) => {
    const confirmPassword = e.target.value;
    setConfirm(confirmPassword);
  };

  const onChangeCode = (e) => {
    const code = e.target.value;
    set_referral_code(code);
  };

  const handleRegister = (e) => {
    e.preventDefault();
    setMessage("");
    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      setLoading(true);

      const registerPromise = referral_code
        ? AuthService.register(username, password, email, referral_code)
        : AuthService.register(username, password, email);

      registerPromise
        .then(
          (user) => {
            ReactGA.event({
              category: "User",
              action: "Created an Account",
            });
            dispatch({ type: ACTIONS.SET_USER, payload: user });
            history({
              pathname: "/explore/",
              search: "?quiz=true",
            });
            history("/explore");
          },
          (error) => {
            let resMessage = error.message;
            if (!resMessage) {
              resMessage = Object.values(error).map((res) => (
                <>
                  {res}.
                  <br />
                </>
              ));
            }
            setMessage(resMessage);
          }
        )
        .finally(() => {
          setLoading(false);
        });
    }
  };


  const handleResponse = (data) => {
    let loginRequest = {
      logintype: "fb",
      email: data?.profile?.email,
      auth_token: data?.tokenDetail?.accessToken,
    };
    setLoading(true);
    AuthService.login({ ...loginRequest }).then(
      (user) => {
        dispatch({ type: ACTIONS.SET_USER, payload: user });
        history("/explore/");
        window.gtag("event", "login", {
          event_category: "access",
          event_label: "login",
        });
      },
      (error) => {
        let resMessage = error.message;
        if (!resMessage) {
          resMessage = Object.values(error).join(" ");
        }
        setLoading(false);
        setMessage(resMessage);
      }
    );
  };

  const handleError = (error) => {
    console.log({ error });
  };

  const responseGoogle = (data) => {
    // console.log("Google Login Response -----> ", data);
    let loginRequest = {
      logintype: "google",
      email: data?.profileObj?.email,
      auth_token: data?.tokenId,
    };
    setLoading(true);
    AuthService.login({ ...loginRequest }).then(
      (user) => {
        dispatch({ type: ACTIONS.SET_USER, payload: user });
        history("/explore/");
        window.gtag("event", "login", {
          event_category: "access",
          event_label: "login",
        });
      },
      (error) => {
        let resMessage = error.message;
        if (!resMessage) {
          resMessage = Object.values(error).join(" ");
        }
        setLoading(false);
        setMessage(resMessage);
      }
    );
  };

  const twitterAuthHandler = (err, data) => {
    console.log(err, data);
  };

  return (
    <>
      {/* <div className={styles.socialLogin}>
        <p>Sign Up With</p> 
        <FacebookProvider appId="296548647958945">
          <Login
            scope="email"
            onCompleted={handleResponse}
            onError={handleError}
          >
            {({ loading, handleClick, error, data }) => (
              <img onClick={() => handleClick} src={facebookIcon} />
            )}
          </Login>
        </FacebookProvider>

        <GoogleLogin
          clientId="1089250428004-bk1bod1p0g45aigil6i00qu449k651kt.apps.googleusercontent.com"
          render={(renderProps) => (
            <img
              onClick={() => renderProps.onClick}
              disabled={renderProps.disabled}
              src={googleIcon}
            />
          )}
          buttonText="Login"
          onSuccess={() => responseGoogle}
          onFailure={() => responseGoogle}
          cookiePolicy={"single_host_origin"}
        />

        <TwitterLogin
          buttonTheme={"light_short"}
          authCallback={twitterAuthHandler}
          className={styles.twitterBtn}
          consumerKey={"LChjGaEgjDZMWuunZksE3xF3Y"}
          consumerSecret={"CeDEgOp4WB33hFbtcswAOoxJb9upici2gqipxpygTqaULhdCWE"}
          children={<img className={styles.twitterIcon} src={twitterIcon} />}
        />
      </div> */}
      <div className={styles.signupModal} style={{ marginTop: "30px" }}>
        <Form onSubmit={handleRegister} ref={form}>
          <div className={styles.signupInputWrapper}>
            <Input
              className={styles.userNameInput}
              autoFocus
              type="text"
              name="username"
              value={username}
              onChange={onChangeUsername}
              validations={[required, verifyUsername]}
              placeholder="Username"
              TextField
            />

            <Input
              type="text"
              name="email"
              value={email}
              onChange={onChangeEmail}
              validations={[required, validEmail]}
              placeholder="Email"
            />

            <Input
              type="password"
              name="password"
              value={password}
              onChange={onChangePassword}
              validations={[required, verifyPassword]}
              placeholder="Enter a password"
              TextField
            />

            <Input
              type="password"
              name="confirmPassword"
              value={confirmPassword}
              onChange={onChangeConfirmPassword}
              validations={[required, equalPassword]}
              placeholder="Confirm password"
              TextField
            />

            <Input
              type="text"
              name="Referral Code"
              value={referral_code}
              onChange={onChangeCode}
              placeholder="Referral Code (Optional)"
            />

            <div className={styles.signupBtnWrapper}>
              <button
                type="submit"
                style={{
                  background:
                    "linear-gradient(90deg, #fe601f 0%, #ea1441 52%, #b9129c 100%)",
                }}
              >
                Join For Free
              </button>
              <p>
                Already have an account ?{" "}
                <button
                  type="button"
                  onClick={setLoginMode}
                  style={{
                    backgroundColor: "rgb(142, 146, 193)",
                    color: "white",
                    marginRight: "20px",
                    border: "none",
                    width: "70px",
                    height: "30px",
                    borderRadius: "5px",
                    fontSize: "16px",
                    boxShadow: "none",
                    cursor: "pointer",
                  }}
                >
                  Sign In
                </button>
              </p>
            </div>
          </div>

          {message && (
            <div className={styles.errorMsg}>
              <p>{message}</p>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
        <div className={styles.signupInfo}>
          <p>
            By clicking "Join For Free", you are agreeing to our
            <span>
              <Link to="/terms-of-use"> Terms of Service </Link>
            </span>
            and{" "}
            <span>
              {" "}
              <Link to="/privacy-policy"> Privacy Policy </Link>
            </span>
            and that you are 18 years of age or older.
          </p>
        </div>
      </div>
    </>
  );
}

export default Signup;
